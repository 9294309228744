:root {
    --header-bg_color:#002970;
    --header-color:#fff;
    --header-color_hover:#FA514C;
    --balance-heading:#f4f8fba6;
    --balance-value:#f4f8fb;
    --profile-dropdown_color: #000;
    --profile-dropdown_color_hover: #00baf2;
    --profile-dropdown_bg_hover: #edfafe;
    --login-btn-color:#fff;
    --login-btn-bg:#00baf2;

    --tab-link-color: #fff;
    --tab-link-color-hover: #141414;
    --tab-link-bgColor: rgba(255, 255, 255, 0.06);
    --tab-link-bgColor-hover: rgba(255, 255, 255, 1);

    --tab-content-bgColor: #dfdfdf;
    --tab-content-boxShadow: 0 1px 1px #dfdfdf;
    --tab-content-left-bgColor:#fff;
    --tab-content-input-color:#141414;
    --tab-content-input-border:1px solid rgba(0, 0, 0, 0.15);
    --tab-content-border: 1px solid #e5e5e5;

    --footer-border-color:#1434A4;
    --footer-border-link-color:#4682B4;
    --footer-contact-h2-color:#1F2857;
    --footer-img-shadow: 0px 1px 2px #00000036;
    --footer-socile-icon-bgColor: #026b97;
    --footer-socile-icon-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(255, 255, 255, 0.1) 0px 0px 1px 0px;
    

    --GrowYourBussionWrapper-bgColor: #fff;
    --GrowYourBussion-wrapper-img-bgColor:#edfafe;

    --blue: #1e90ff;
    --white: #ffffff;
  }

  .contentwrapper {
    background-color: #fff;
    padding-block: 20px;
  }
  .conditions-heading,.heading-5{
  
    max-width: 1000px!important;
    margin-left: auto !important;
    margin-right: auto !important;
    background-color: #401600;
    padding-inline: 20px;
    
  }

  .conditions-heading .heading-4,.heading-5{
    color: #fff !important;
  }

  .refundPolicyTop{
    padding: 20px 40px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 20px auto 30px;
    line-height: 2;
    max-width: 1000px;
    border-radius: 20px;
  
}
/* .condition-wrapper{
  margin: 0 40px;
} */

.heading-4 {
  font-size: 25px;
  font-weight: 600;
  color: #1f2857;
  line-height: 40px;
}


  .heading-5{
    font-size: 20px;
    font-weight: 600;
    color: #1F2857;
    line-height: 40px;
  }
  .disc-list {
    list-style-type: disc;
  }

  @media only screen and (max-width: 480px){
    .condition-wrapper{
      margin: 0 0;
    }
  }


.pageLoaderWrapper{
  width: 100%;
  height: 300px;
}
.pageloader {
  width: 50px;
  aspect-ratio: 1;
  display:grid;
  -webkit-mask: conic-gradient(from 15deg,#D44100,#5c1c0086);
  animation: l26 1s infinite steps(12);
}
.pageloader,
.pageloader:before,
.pageloader:after{
  background:
    radial-gradient(closest-side at 50% 12.5%,
    #D44100 96%,#5c1c0086) 50% 0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%,
    #D44100 96%,#5c1c0086) 0 50%/80% 20% repeat-x;
}
.pageloader:before,
.pageloader:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.pageloader:after {
  transform: rotate(60deg);
}

.Home-page h2{
 font-size: 34px;
 color: #fff;
 height: 64px;
 text-align: center;
 margin-top: 20px;
 margin-bottom: 30px;

}

@keyframes l26 {
  100% {transform:rotate(1turn)}
}

@media only screen and (max-width: 768px){
  .Home-page h2{
    font-size: 24px;
    height: auto;
    line-height: 30px;
  }
}