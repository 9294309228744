footer {
   
    background-image: url('../../assets/images/footerBg.svg');
    background-size: calc(100% + 140px);
    background-position: bottom -80px left -70px;
    background-repeat: no-repeat;
    color: var(--header-color);
    padding-top: 266px;
    font-size: 13px;
    position: relative;
    z-index: 1;
}

footer>div.footer {
    padding-bottom: 40px;
}

.footer {
    padding-top: 20px;
    padding-bottom: 80px;
   
}

.footer a {
    color: #fff;
    display: block;
    margin: 4px 0;
    white-space: nowrap;
    transition: all .3s;

}

footer a:hover {
    color: var(--header-color);
}

.footer .logo {
    max-width: 255px;
    color: var(--footer-border-link-color);
}

.footer .logo img {
    margin-bottom: 25px;
}

.footer .menus {
    /* max-width: 767px; */
}

.footer .menus h4 {
    margin-bottom: 20px;
}

.footer .menus h4 {
    margin-bottom: 20px;
}

.footer .downloadMeritPay>img {
    display: block;
    max-width: 135px;
    margin-left: 4px;
    margin-top: 10px;

}

.footer-botton {
    padding: 0px 0;
    color: #fff;
    font-size: 15px;
}

.footer-botton a {
    color: var(--footer-border-link-color);
}

footer .socal-icons div {
    width: 37px;
    height: 37px;
    background-color: #ffffff33;
    box-shadow: var(--footer-socile-icon-shadow);
    border-radius: 50%;
    font-size: 20px;
    margin-right: 10px;
}

footer .socal-icons div a {
    color: #fff;
}

footer .socal-icons div:hover a {
    color: #FA514C;
   
}


.footer-contact-wrapper {
    padding: 20px 0;
}

.footer-contact-wrapper .box {
    max-width: 300px;
    text-align: center;
    padding: 20px;
    transition: all .3s;
}



.footer-contact-wrapper .box .img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--white);
    margin: 0 auto 12px;
    box-shadow: var(--footer-img-shadow);
}

.footer-contact-wrapper .box h2 {
    color: var(--footer-contact-h2-color);
    margin-bottom: 8px;
}

.footerBottom{
 justify-content: center!important;
 align-items: center;
 gap: 40px;
 padding-bottom: 30px!important;

}



@media only screen and (max-width: 767px) {
    footer{
        background-size: calc(1500px + 140px);
        background-position: bottom -80px left -80px;
    }
    .footerContactwrapper .footer-contact-wrapper {
        flex-wrap: wrap;
    }

    .footer .logo {
        display: flex;
        max-width: 100%;
        gap: 20px;
    }


   

    .footer .menus {
        flex-wrap: wrap;
        gap: 20px;
    }

    .footerBottom {
        flex-direction: column;
    }

    .footerBottom>div {
        padding: 10px 0;
    }
} 

@media only screen and (max-width: 600px) {
    footer{
        background-size: calc(1500px + 140px);
        background-position: bottom -80px left -80px;
    }
    .footer {
        flex-wrap: wrap;
    }
   
} 
 @media only screen and (max-width: 580px) {
 footer{
        background-size: calc(1800px + 140px);
    }
}